import React from "react";

const Default = () => {
    return (
        <div className="flex h-screen">
            <div className="flex flex-col justify-center items-start w-1/2 p-10">
                <div className="absolute top-6 left-6">
                    <img
                        src="assets/icon/icon.png"
                        alt="Logo"
                        className="h-8"
                    />
                </div>
                <h1 className="text-5xl font-bold mt-4">Testing</h1>
                <p className="text-gray-500 mt-2">Testing page</p>

                <div className="absolute bottom-6 left-6 flex space-x-4 text-gray-500">
                    <a
                        href="https://www.earcandytech.com"
                        className="hover:underline"
                    >
                        Home
                    </a>
                    <span>·</span>
                    <a
                        href="https://www.earcandytech.com/plugins"
                        className="hover:underline"
                    >
                        Plugins
                    </a>
                </div>
            </div>

            <div className="relative w-1/2">
                <img
                    src="assets/back.png"
                    alt="Desert"
                    className="object-cover h-full w-full"
                />
            </div>
        </div>
    );
};

export default Default;
