import "./App.css";
import Default from "./screens/default"

function App() {
    return (
        <div className="h-screen w-screen">
            <Default />
        </div>
    );
}

export default App;
